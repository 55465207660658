.Footer
{
    background: var(--greyish)!important;
    color: var(--low);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    padding: 6rem 0rem 1.5rem 0rem;
}

.footer-top
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 80%;
    gap: 1rem;

}

.footer-top h3
{
    margin-bottom: 1rem;
}

.footer-top a:hover
{
    color: var(--blue);
}

.footer-logo
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.footer-logo img {
  width: auto;
  height: 50px;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


hr
{
    background-color: var(--grey);
    width: 80%;
}

@media screen and (max-width: 2560px) and (min-width: 1500px)
{
    .Footer
    {
        padding: 6rem 0rem 1.5rem 0rem;
    }
    .footer-top
    {
        width: 80%;
    }
    .footer-top h3
    {
        font-size: 2rem;
    }
    .footer-top a 
    {
        font-size: 1.5rem;
    }
    .footer-bottom p
    {
        font-size: 1.5rem;
    }
    .footer-logo img
    {
        height: 100px;
    }
    .footer-logo p
    {
        font-size: 1.5rem;
    
    }
    
}

@media screen and (max-width: 768px)
{
    .Footer
    {
        padding: 3rem 0rem 1.5rem 0rem;
    }
    .footer-top
    {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
    }
    .footer-bottom
    {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
    }
    .footer-logo
    {
        align-items: flex-start;
        
    }
    .footer-logo img
    {
        height: 40px;
    }
    
}