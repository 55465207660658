* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: #f3f4f5;
  color: var(--low);
  font-family: var(--font-family);
}
.App {
}

.background-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind other content */
}

.background-gradient_blue {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 50%, /* Start with transparent color */
    rgba(48, 71, 222, 0.327) 100% /* End with the desired color */
  );
  mix-blend-mode: difference; /* Standard property */
  mix-blend-mode: difference; /* Standard property */
  backdrop-filter: blur(10px); /* Standard property */
  -webkit-backdrop-filter: blur(10px); /* Safari prefix */
  z-index: 2;
}

/* .background-gradient_blue2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    #000000 50%,
    rgba(48, 71, 222, 0.327) 100%
  );
  backdrop-filter: blur(10px);
  z-index: 1; 
} */

/* Scrollbar.css */

/* Track */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a5a5b59;
  border-radius: 12px;
  height: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

/* Track when the content is being scrolled */
::-webkit-scrollbar-track {
  background: none;
}

/* Add a slight border around the scrollbar */
::-webkit-scrollbar-track-piece {
  border: 0px solid #2b2b2b4c;
  height: 5px;
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.sjdn {
  height: 100vh;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
