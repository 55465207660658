.bookcall {
  display: flex;
  flex-direction: column;

  color: var(--low);
  height: 100%;
  width: 100%;
  text-align: center;
}

.bookcall-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.bookcall-calendly iframe {
  width: 100%;
  height: 700px;
  border: none;
}



@media screen and (max-width: 2560px) and (min-width: 1500px) {

  .bookcall 
  {
    flex-direction: row;
    justify-content: space-between;

  }

  .bookcall-heading 
  {
    flex-wrap: wrap;
    flex: 0.4;
    padding-left: 10rem;
  }

  .bookcall-calendly
  {
    flex: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}



