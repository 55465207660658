.navbar
{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;

    color: var(--low);

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1.5rem 2rem 1rem 2rem;

    backdrop-filter: blur(10px);
    z-index: 10;
    
}

.navbar-logo:hover
{
    cursor: pointer;
    color: var(--blue);
}

.navbar-laptop
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
}

.navbar-links a:hover
{
    transition: ease-in-out;
    color: var(--blue);
    cursor: pointer;
}


.navbar-buttons
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    cursor: pointer;
}

.navbar-buttons button
{
    cursor: pointer;
}


.navbar-buttons button:hover
{
    color: var(--blue);
   background: none;
    transition: ease-in-out;
}

.navbar-phone
{
    display: none;
}

.opacity
{
    background: rgba(0, 0, 0,0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.navbar-phone-container
{
    display: flex;
    flex-direction: column;
    background: var(--low);
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 100vh;
    color: var(--high);
    width: 40%;
    z-index: 1;
}

.navbar-phone-links
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem;
}

.close-menu
{
    z-index: 2;
    
}

@media screen and (max-width: 2560px) and (min-width:1500px) {
    .navbar
    {
        padding: 2.5rem 4rem 1.5rem 4rem;
    }
    .navbar-logo h1
    {
        font-size: 4rem;
    
    }
    .navbar-links a 
    {
        font-size: 2rem;
    }
}

@media screen and (max-width: 768px)
{
    .navbar
    {
        padding: 1rem 2rem 1rem 2rem;
        width: 100%;
    }

    .navbar-laptop
    {
        display: none;
    }

    .navbar-buttons
    {
        display: none;
    }
    .navbar-phone
    {
        display: flex;
    }
    .navbar-links
    {
        display: none;
    }
}

@media screen and (max-width: 550px)
{
    .navbar-phone-container
    {
        width: 60%;
    }
    
}

@media screen and (max-width: 426px)
{
    .navbar
    {
        padding: 1rem 1.25rem 1rem 1.25rem;
    }
    .navbar-phone-container
    {
        width: 65%;
    }

    .navbar-logo h1
    {
        font-size: 1.75rem;
    }
    
}


@media screen and (max-width:400px) {
    .navbar-logo h1
    {
        font-size: 1.5rem;
    }
    .open-menu, .close-menu
    {
        font-size: 1.5rem;
    }
}

@media screen and (max-width:320px) {
  
}