.home_news
{
  margin: 10rem 0rem 3rem 0rem;
  margin-bottom: 7.5rem;
  font-family: var(--font-family);
  text-align: center;
}
.news-heading
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.news-heading h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 2.5rem;
  color: var(--color-dark);
}


.news-carousel-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.news-card-container {
  display: flex;
  scroll-behavior: smooth;
  margin: 1rem 1rem 0 1rem;
}

.news-container {
  margin: 1rem;
}

.prev-btn, .next-btn
{
  background: none;
  border: none;
  font-size: 2rem;
  color: rgb(86, 83, 83);
}

.prev-btn:disabled, .next-btn:disabled
{
  color: #d9dcdc;
  
}
.prev-btn:hover, .next-btn:hover
{
  color: black;
  background: none;
}

.prev-btn:disabled:hover, .next-btn:disabled:hover
{
  color: #d9dcdc;
  cursor:default;
}

.prev-btn p,
.next-btn p {
  margin: 0;
}
.next-btn {
  right: 0;
}

.news_buttons
{
  width: 90%;
  padding: 0 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error-message
{
  font-size: 1.5rem;
}

@media screen and (max-width: 2560px) and (min-width: 1500px) {
  .home_news {
    margin: 15rem 0rem 5rem 0rem;
  }
  .news-heading h1 {
    font-size: 6rem;
  }
  .error-message {
    font-size: 4rem;
  }
  .prev-btn, .next-btn {
    font-size: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .news-container
  {
    margin: 0.5rem;
  }
  
}


@media screen and (max-width:975px) {
  .news-carousel-container
  {
    flex-direction: column;
  }
  .news-card-container
  {
    flex-direction: column;
  }
}