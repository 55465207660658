.news-card
{
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: white;
    box-shadow: 10px 10px 5px 0px #888888;
    padding: 0.1rem;
    width: 18rem;
    margin-bottom: 1rem;
    height: 30rem;
    gap: 0.5rem;
    font-family: var(--font-family);
    overflow: hidden;

}

.news-card-image
{
    
    height: 12rem;
    margin-bottom: 0.5rem;
}

.news-card-image img
{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: contain;
    
}

.news-card-body{
    padding: 0rem 1rem 1rem 1rem;
    overflow-y: auto;
    word-wrap: break-word;
    text-wrap: wrap;
}

.news-card-title
{
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    height: 5rem;
}
.news-card-text
{
    height: 6rem;
}

.read-more
{
    height: 5rem;
    font-size: 0.9rem;
}
.news-card-text
{
    font-size: 1rem;
}
.text-muted
{
    font-size: 0.85rem;
    padding: 0.2rem;
    
}
.news-card-text a
{
    color: blue;
    margin-left: 0.1rem;
}

.news-card-text a:hover
{
    color: green;
}

@media screen and (max-width: 2560px) and (min-width: 1550px) {
    .news-card {
      width: 32.5rem;
      height: 50rem;
    }
    .news-card-image {
      height: 20rem;
    }
    .news-card-image img {
      height: 20rem;
    }
    .news-card-body {
      padding: 0rem 2rem 2rem 2rem;
      overflow-y: auto;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      height: 10rem;
    }
    .read-more {
      height: 5rem;
    }
    .news-card-title {
      font-size: 2.75rem;
      margin-bottom: 1rem;
      height: 10rem;
      overflow-y: hidden;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }
    .read-more {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }
    .text-muted {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 768px){
    .news-card {
      width: 25.5rem;
      height: 40rem;
    }
    .news-card-image {
      height: 15rem;
    }
    .news-card-image img {
      height: 15rem;
    }
    .news-card-body {
      padding: 0rem 2rem 2rem 2rem;
      overflow-y: auto;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      height: 10rem;
    }
    .read-more {
      height: 5rem;
    }
    .news-card-title {
      font-size: 2rem;
      margin-bottom: 0;
      height: 8rem;
      overflow-y: hidden;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    .read-more {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    .text-muted {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 426px){
    .news-card {
      width: 20rem;
      height: 35rem;
    }
    .news-card-image {
      height: 12.5rem;
    }
    .news-card-image img {
      height: 15rem;
    }
    .news-card-body {
      padding: 0rem 1rem 2rem 1rem;
      overflow-y: auto;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      height: 8rem;
    }
    .read-more {
      height: 5rem;
    }
    .news-card-title {
      font-size: 1.65rem;
      margin-bottom: 0.5rem;
      height: 6rem;
      overflow-y: hidden;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    .read-more {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    .text-muted {
      font-size: 1rem;
    }
  }

  @media screen and (max-width:350px) {
    .news-card {
      width: 16rem;
      height: 28rem;
    }
    .news-card-image {
      height: 9rem;
    }
    .news-card-image img {
      height: 9rem;
    }
    .news-card-body {
      padding: 0rem 1rem 2rem 1rem;
      overflow-y: auto;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      height: 6.5rem;
    }
    .read-more {
      height: 5rem;
    }
    .news-card-title {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      height: 5rem;
      overflow-y: hidden;
      text-wrap: wrap;
      word-wrap: break-word;
    }
    .news-card-text {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .read-more {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .text-muted {
      font-size: 0.8rem;
    }
  }
