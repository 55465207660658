.hover_cta {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  font-family: var(--font-family);
  padding: 13rem 4rem 10rem 4rem;
  /* background-color: var(--high); */
  color: var(--low);
}

.hover_cta__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3%;
}

.hover_cta__container__icon {
  font-size: 2.5rem;
  color: var(--blue);
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.hover_cta__container__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transform: translateY(20px);
  transition: height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  /* Add a transition for height */
}

.hover_cta__container__text h2 {
  margin: 0 0 0.83rem 0;
}

.hover_cta__container__text h3 {
  color: var(--lower);
  font-weight: 300;
  margin: 0;
}

.hover_cta__container__text h3:hover {
  color:var(--blue);
}

.hover_cta__container__text p {
  color: var(--lower);
  width: 70%;
  text-align: center;
  word-wrap: break-word;
  opacity: 0; /* Initially set opacity to 0 */
  margin-top: 1rem;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.5s ease-in-out; /* Add transitions for height and opacity */
}

.hover_cta__container:hover .hover_cta__container__icon {
  opacity: 0;
  transform: translateY(-20px);
}

.hover_cta__container:hover .hover_cta__container__text {
  transform: translateY(-20px);
  
}

.hover_cta__container:hover .hover_cta__container__text h3 {
  color:var(--blue);
}
.hover_cta__container:hover .hover_cta__container__text p {
  height: auto; /* Set height to auto on hover */
  opacity: 1; /* Set opacity to 1 on hover */
}

@media screen and (max-width: 2560px) and (min-width:1500px){
  

  .hover_cta__container__icon
  {
    font-size: 4rem;
  }

  .hover_cta__container__text h2
  {
    font-size: 2.5rem;
  }
  .hover_cta__container__text h3
  {
    font-size: 2rem;
  }

  .hover_cta__container__text p
  {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .hover_cta__container__icon
  {
    font-size: 2rem;
  }

  .hover_cta__container__text h2
  {
    font-size: 1.25rem;
  }
  .hover_cta__container__text h3
  {
    font-size: 1rem;
  }
  
}

@media screen and (max-width: 768px) {
  .hover_cta {
    flex-wrap: wrap;
    gap: 2rem;
    padding: 7rem 4rem 4rem 4rem;
  }

  .hover_cta__container__icon
  {
    font-size: 2rem;
  }

  .hover_cta__container__text h2
  {
    font-size: 1.25rem;
  }
  .hover_cta__container__text h3
  {
    font-size: 1rem;
  }

}

@media screen and (max-width: 550px) {
  .hover_cta {
    flex-direction: column;
  }

    .hover_cta__container {
        width: 100%;
    }
    
}


