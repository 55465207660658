.Hero {
  height: 105vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

@keyframes scaleUpDown {
  0%,
  100% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(1.1); /* Scale up to 150% */
  }
}

.background-cont
{
  width: 100%;
}
.background{
  position: absolute;
  top: 55%;
  bottom: auto;
  left: auto;
  right: 0%;
  transform: translate(0, -50%);
}

.background img {
  opacity: 0.05;
}

.hero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 2;
}
.hero-title h1 {
  font-size: 3rem;
  font-weight: 800;
  color: var(--color-dark);
  text-align: center;
  text-transform: capitalize;
}

.hero-title p {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--color-dark);
  text-align: center;
  text-transform: capitalize;
}

.hero-title h3
{
  font-weight: 400;
  border: 1px solid var(--mid);
  border-radius: 50px;
  padding: 0.65rem 1.1rem;
  font-size: 1rem;
}

.hero-button button {
  padding: 1rem 2rem;
  font-size: 1rem;
  border: 5px solid var(--color-dark);
  border-radius: 50px;
}
.blue {
  color: var(--blue);
}
.sog_hero-static-text {
}
.sog_hero-dynamic-text {
}
.sog_hero-dynamic-text:after {
  content: "|";
  color: black;
  right: -0.15em;
  font-size: 1.2em;
  font-weight: 300;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}



@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1600px) {
  .Hero {
    padding: 6rem 10rem;
    gap: 4rem;
  }
  .hero-title h3
  {
    font-size: 2rem;
    padding: 1rem 1.5rem;
  }
  .hero-title h1 {
    font-size: 5.5rem;
  }
  .hero-title p {
    font-size: 3rem;
  }
  .hero-vsl iframe {
    width: 1428px;
    height: 775px;
  }
  .hero-button button {
    padding: 2rem 4rem;
    font-size: 2rem;
  }

  .background img {
    width: 75rem;
  }
}

@media screen and (max-width: 1024px) {
  .Hero {
    padding: 4rem 0rem;
  }
  .hero-vsl iframe {
    width: 736px;
    height: 400px;
  }

  .hero-title h1 {
    font-size: 2.65rem;
  }
  .hero-title p {
    font-size: 1.25rem;
  }
  
}

@media screen and (max-width: 800px) {
  .hero-vsl iframe {
    width: 644px;
    height: 350px;
  }
  .hero-title h1 {
    font-size: 2.25rem;
  }
  .hero-title p {
    font-size: 1.25rem;
    width: 80%;
  }
  .background img {
    width: 35rem;
  }
  .hero-title h3
  {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 550px) {
  .hero {
    gap: 2rem;
  }
  .hero-vsl iframe {
    width: 368px;
    height: 200px;
    border-radius: 20px;
  }
  .hero-title {
    gap: 0.5rem;
  }
  .hero-title h1 {
    font-size: 2rem;
  }
  .hero-title p {
    font-size: 1.2rem;
    width: 85%;
  }
  .hero-title h3
  {
    font-size: 0.65rem;
    margin-bottom: 1rem;
  }
  .hero-button button {
    padding: 0.65rem 1.65rem;
    font-size: 1rem;
    border-radius: 45px;
  }

  .background {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .background img {
    width: 25rem;
  }
}

@media screen and (max-width: 430px) {
  .hero {
    padding: 2rem 1rem;
    gap: 2rem;
  }
  .hero-vsl iframe {
    width: 310px;
    height: 170px;
    border-radius: 20px;
    border: 3px solid var(--color-dark);
    font-size: 1rem;
  }
  .hero-title h1 {
    font-size: 1.75rem;
  }
  .hero-title p {
    font-size: 1rem;
  }
  .hero-title h3
  {
    font-size: 0.65rem;
  }
  .hero-button button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-radius: 20px;
  }
}

@media screen and (max-width: 400px) {
  .hero-vsl iframe {
    width: 320px;
    height: 170px;
    border-radius: 20px;
    border: 3px solid var(--color-dark);
    font-size: 1rem;
  }
  .hero-title h1 {
    font-size: 1.3rem;
  }
  .hero-title p {
    font-size: 0.9rem;
  }
  .hero-title h3
  {
    font-size: 0.6rem;
    padding: 0.3rem 0.8rem;
    margin-bottom: 0rem;
  }
  .background img {
    width: 22.5rem;
  }
}

@media screen and (max-width: 350px) {
  .hero-vsl iframe {
    width: 280px;
    height: 150px;
    border-radius: 20px;
    border: 3px solid var(--color-dark);
    font-size: 0.9rem;
  }
  .hero-title h1 {
    font-size: 1.25rem;
  }
  .hero-title p {
    font-size: 0.85rem;
  }
  .hero-button button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-radius: 20px;
  }
  .background img
  {
    width: 20rem;
  }
}
