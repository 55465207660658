@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Montserrat", sans-serif;
  --low: #161616;
  --lowish: rgba(235, 233, 233, 0.498);
  --high: #f3f4f5;
  --mid: grey;
  --blue: #00b0ff;
  --greyish: rgba(124, 125, 135, 0.135);
  --opacity-blue: #00aeff48;
}

.heading h1 {
  font-size: 2.5rem;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

.content p {
  font-size: 1.25rem;
  line-height: 2rem;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  flex: 0.8;
  text-align: center;
}

.cta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.65rem 2rem;

  color: var(--high);
  background-color: var(--low);
  

  border: none;
  border-radius: 5px;

  font-size: 1.1rem;
  font-weight: 600;
}

.cta:hover
{
  transform: scale(1.05);
  color: var(--blue);
  background: none;
  cursor: pointer;
}

.phone-cta {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.65rem 1rem 0.65rem 1rem;

  color: var(--low);
  background-color: var(--high);

  border: none;
  border-radius: 5px;

  font-size: 1rem;
  text-align: left;
}

.container {
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  
  width: 100%;  
}

.container-box
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;

  padding: 2rem;
  border-width: 1px;
  /* border-color: 
    rgb(156, 182, 242,0.2);
  
  border-style: solid;
  border-radius: 12px;
  background: radial-gradient(
    150% 100% at 50% 15.8%,
    rgb(0, 12, 41,0.2) 36.87887105855856%,
    var(--token-762b6622-309d-4974-b924-a2c77b25a1e3, rgb(66, 115, 230,0.5))
     100%
  ); */
  border: 1px solid var(--lowish);
  background-color: var(--greyish);
  border-radius: 12px;
  /* box-shadow: 10px 10px 20px var(--opacity-blue); */
  opacity: 1;
}

@media screen and (max-width: 2560px) and (min-width: 1500px) {
  .cta {
    padding: 1.25rem 3.5rem;
    font-size: 2.05rem;
    border-radius: 8px;
  }
  .heading h1{
    font-size: 3.5rem;
  }
  .text h2
  {
    font-size: 3rem;
  }
  .content p, .text p {
    font-size: 2rem;
  }
  .about-container
  {
    width: 80%;
  }
  .container
  {
    gap: 5rem;
  }
  .container-box
  {
    padding: 3rem 4rem;
  }
}


@media screen and (max-width: 1024px) {
  .heading h1{
    font-size: 2.25rem;
  }
  .text h2
  {
    font-size: 1.5rem;
  }
  .content p {
    font-size: 1.15rem;
  }
  .about-container
  {
    width: 85%;
  }
}

@media screen and (max-width: 768px) {
  .heading h1{
    font-size: 2rem;
  }
  .text h2
  {
    font-size: 1.75rem;
  }
  .content p {
    font-size: 1.15rem;
  }
  .cta {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 550px) {
  .heading h1{
    font-size: 1.75rem;
  }
  .text h2
  {
    font-size: 1.5rem;
  }
  .content p {
    font-size: 1.1rem;
  }
  .about-container
  {
    width: 95%;
  }
}

@media screen and (max-width: 430px) {
  .heading h1{
    font-size: 1.5rem;
  }
  .text h2
  {
    font-size: 1.35rem;
  }
  .about-container
  {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .heading h1{
    font-size: 1.3rem;
  }
  .text h2
  {
    font-size: 1.25rem;
  }
  .content p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 320px) {
  .heading h1{
    font-size: 1.25rem;
  }

  .text h2
  {
    font-size: 1.15rem;
  }
}
